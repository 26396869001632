import React from "react";
import { FaGear } from "react-icons/fa6";
import { TbLogout2 } from "react-icons/tb";
import axios from "../lib/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../redux/features/authSlice";
import { Link, useNavigate } from "react-router-dom";
import Setting from "./navitems/Setting";
import NavPurchase from "./purchases/NavPurchase";
import CreatePurchase from "./purchases/CreatePurchase";
import Create from "./purchases/payments/Create";
import NavMaterial from "./materials/NavMaterial";
import CreateMaterial from "./materials/CreateMaterial";
import NavPreparation from "./preparations/NavPreparation";
import DistributeCreate from "./distributes/Create";
import NavSale from "./sales/NavSale";
import CreateSale from "./sales/CreateSale";
import NavExpense from "./expenses/NavExpense";
import PCreate from "./sales/payments/Create";
import ReportPurchase from "./purchases/ReportPurchase";
import ReportPurchasePayment from "./purchases/payments/ReportPurchasePayment";
import ReportSale from "./sales/ReportSale";
import ReportSalePayment from "./sales/payments/ReportSalePayment";
import ReportExpense from "./expenses/ReportExpense";
import ReportMaterial from "./materials/ReportMaterial";
import ReportDistribute from "./distributes/ReportDistribute";

function Nav() {
  const permissions = useSelector((state) => state.auth.permissions);
  const purchaseCreateForm = useSelector((state) => state.purchase.createForm);
  const purchaseReportForm = useSelector((state) => state.purchase.report);
  const purchasePaymentReportForm = useSelector(
    (state) => state.purchase.paymentReport
  );
  const saleCreateForm = useSelector((state) => state.sale.createForm);
  const saleReportForm = useSelector((state) => state.sale.report);
  const salePaymentReportForm = useSelector(
    (state) => state.sale.paymentReport
  );
  const purchasePaymentCreateForm = useSelector(
    (state) => state.purchase.paymentCreateForm
  );
  const salePaymentCreateForm = useSelector(
    (state) => state.sale.paymentCreateForm
  );
  const materialCreatForm = useSelector((state) => state.material.createForm);
  const preparationCreateForm = useSelector(
    (state) => state.preparation.createForm
  );
  const distributeCreateForm = useSelector(
    (state) => state.distribute.createForm
  );

  const expenseReportForm = useSelector((state) => state.expense.report);
  const materialReportForm = useSelector((state) => state.material.report);
  const distributeReportForm = useSelector((state) => state.distribute.report);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();

    axios
      .post("/api/logout")
      .then((data) => {
        clearCatch();
      })
      .catch((error) => {
        console.log(error);
        clearCatch();
      });
  };

  const clearCatch = () => {
    localStorage.setItem("user", null);
    localStorage.setItem("authToken", null);
    dispatch(setUser(null));
    dispatch(setToken(null));
    navigate("/login");
  };
  return (
    <div className="flex flex-col lg:flex-row lg:justify-between bg-gray-800 text-gray-300 px-4 lg:px-8 py-2">
      <div className="lg:flex lg:gap-4 lg:items-center">
        <Link to="/" className="hover:text-white font-bold p-1">
          داشبورد
        </Link>
        <NavPurchase />
        {permissions["view materials"] && <NavMaterial />}
        <NavPreparation />
        {permissions["view products"] && (
          <Link to="/products" className="hover:text-white font-bold p-1">
            گدام محصولات
          </Link>
        )}
        <NavSale />
        {permissions["view accounts"] && (
          <Link to="/accounts" className="hover:text-white font-bold p-1">
            حسابات
          </Link>
        )}
        {permissions["view expenses"] && <NavExpense />}
      </div>
      <div className="flex lg:gap-4 lg:items-center">
        <Setting />

        <button
          onClick={handleLogout}
          className="bg-gray-100 hover:bg-gray-400 p-2 rounded-md"
        >
          <TbLogout2 className="text-gray-800" size={18} />
        </button>
      </div>
      {materialCreatForm && <CreateMaterial />}
      {purchaseCreateForm && <CreatePurchase />}
      {saleCreateForm && <CreateSale />}
      {purchasePaymentCreateForm && <Create />}
      {salePaymentCreateForm && <PCreate />}

      {distributeCreateForm && <DistributeCreate />}
      {purchaseReportForm && <ReportPurchase />}
      {purchasePaymentReportForm && <ReportPurchasePayment />}
      {saleReportForm && <ReportSale />}
      {salePaymentReportForm && <ReportSalePayment />}

      {expenseReportForm && <ReportExpense />}
      {materialReportForm && <ReportMaterial />}
      {distributeReportForm && <ReportDistribute />}
    </div>
  );
}

export default Nav;
