import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  report: false,
};

export const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {
    showReportForm: (state) => {
      state.report = !state.report;
    },
  },
  extraReducers: (builder) => {},
});

export const { showReportForm } = expenseSlice.actions;
export default expenseSlice.reducer;
