import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../../lib/axios";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { useEffect } from "react";
import {
  setDistributeId,
  setDistributeObj,
} from "../../redux/features/distributeSlice";
import { toEn } from "../../lib/toEn";
import { FaCheck } from "react-icons/fa";

const Edit = () => {
  const dispatch = useDispatch();
  const editObj = useSelector((state) => state.distribute.editObj);
  const [loading, setLoading] = useState(false);

  const [preparations, setPreparations] = useState([]);

  const [formData, setFormData] = useState(editObj);
  const [maxValue, setMaxValue] = useState(0);
  const [selected, setSelected] = useState(null);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    if (preparations.length <= 0) {
      getPreparations();
    }
    getTeams();
  }, []);

  const getPreparations = () => {
    axios
      .get("/api/preparations/select2")
      .then((data) => {
        setPreparations(data);
        const select = data.find((row) => row.value === editObj.preparation_id);
        setSelected(select);
        setMaxValue(select.qtys);
        setFormData((prevValue) => ({
          ...prevValue,
          preparation_id: select.value,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTeams = () => {
    axios
      .get("/api/settings/team/select2")
      .then((data) => {
        setTeams(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setLoading(true);
    axios
      .put(`api/distribute/${editObj.id}/update`, { ...formData })
      .then((data) => {
        console.log("data", data);
        setLoading(false);
        if (data.status === 201) {
          toast.success("عملیات موفقانه انجام شد.");
          close();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const close = () => {
    dispatch(setDistributeObj(null));
    dispatch(setDistributeId(null));
  };

  const handleItemChange = (index, e) => {
    const value = toEn(e);
    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    if (regex.test(value)) {
      setFormData((prevValue) => ({
        ...prevValue,
        [index]: value,
      }));
    }
  };

  return (
    <>
      <div className="fixed inset-0 opacity-35 bg-black z-10"></div>
      <div className="fixed inset-0 flex justify-center items-center z-10">
        <div className="relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
            <div className="flex justify-between p-2 border-b text-gray-500 font-semibold ">
              <p>فورم ویرایش توزیع</p>

              <button
                type="button"
                className="hover:text-gray-700 hover:font-bold"
                onClick={() => close()}
              >
                <IoClose size={18} />
              </button>
            </div>

            <form
              action=""
              onSubmit={handleSubmit}
              className="p-4 text-gray-500"
              id="createSaleForm"
            >
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="distributed_date" className="w-1/3 md:w-1/4">
                  تاریخ توزیع <span className="text-red-500">*</span>
                </label>
                <input
                  name="distributed_date"
                  type="date"
                  required
                  onChange={(e) => {
                    setFormData((prevValue) => ({
                      ...prevValue,
                      distributed_date: e.target.value,
                    }));
                  }}
                  value={formData.distributed_date}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              {selected && (
                <div className="flex gap-8 items-center mb-3">
                  <label htmlFor="name" className="w-1/3 md:w-1/4">
                    نمبر تهیه<span className="text-red-500">*</span>
                  </label>
                  <Select
                    className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                    placeholder="انتخاب"
                    required
                    onChange={(e) => {
                      const balance = preparations.find(
                        (row, index) => row.value === e.value
                      ).balance;
                      setFormData((prevValue) => ({
                        ...prevValue,
                        purchase_id: e.value,
                        amount: balance,
                      }));
                      setMaxValue(balance);
                    }}
                    options={preparations}
                    value={selected}
                  />
                </div>
              )}

              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  تیم خیاطی<span className="text-red-500">*</span>
                </label>
                <Select
                  className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                  placeholder="انتخاب"
                  required
                  onChange={(e) => {
                    setFormData((prevValue) => ({
                      ...prevValue,
                      tailor_team_id: e.value,
                    }));
                  }}
                  value={teams.find(
                    (row) => row.value === formData.tailor_team_id
                  )}
                  options={teams}
                />
              </div>

              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  تعداد <span className="text-red-500">*</span>
                </label>
                <input
                  name="qtys"
                  type="text"
                  required
                  max={maxValue}
                  onChange={(e) => handleItemChange("qtys", e)}
                  value={formData.qtys}
                  placeholder="100.00"
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="distributed_date" className="w-1/3 md:w-1/4">
                  تاریخ دریافت <span className="text-red-500">*</span>
                </label>
                <input
                  name="received_date"
                  type="date"
                  required
                  onChange={(e) => {
                    setFormData((prevValue) => ({
                      ...prevValue,
                      received_date: e.target.value,
                    }));
                  }}
                  value={formData.received_date}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
            </form>

            <div className="flex  py-2 px-4 bg-gray-50 border-t justify-end">
              <button
                data-modal-hide="popup-modal"
                form="createSaleForm"
                type="subbmit"
                className="flex text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 py-1 px-4 rounded-sm"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
                ) : (
                  <FaCheck className="mt-1 mx-2" />
                )}
                ثبت گردد
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
