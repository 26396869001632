import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import axios from "../../../lib/axios";
import { Link } from "react-router-dom";
import Delete from "../../../components/Delete";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { setDelete } from "../../../redux/features/deleteSlice";
import toast from "react-hot-toast";

function TeamList() {
  const [teams, setTeams] = useState([]);
  const deleteItem = useSelector((state) => state.delete.item);
  const inputs = {
    name: "",
    manager_name: "",
    manager_phone: "",
    manager_address: "",
    total_machine: "",
  };
  const [team, setTeam] = useState(inputs);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!deleteItem.id) {
      getTeams();
    }
  }, [deleteItem]);

  const getTeams = () => {
    axios
      .get("/api/settings/teams")
      .then((data) => {
        setTeams(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTeam = (index, value) => {
    setTeam((prevValue) => ({ ...prevValue, [index]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("teaam", team);
    axios
      .post("/api/settings/team/create", { id, ...team })
      .then((data) => {
        console.log("dooooo", id);
        if (id) {
          setTeams((prevTeams) =>
            prevTeams.map((row) => {
              if (row.id === id) {
                return data;
              }
              return row;
            })
          );
        } else {
          setTeams([data, ...teams]);
        }
        setLoading(false);
        toast.success("موفقانه ثبت گردید.");
        setTeam(inputs);
        setId(null);
      })
      .catch((error) => {
        setLoading(false);
        const message = error?.response?.data?.message;
        toast.error(message ? message : error.message);
        console.log(error);
      });
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>تیم های خیاطی</h3>
      </div>
      <div className="p-4 flex flex-row gap-6">
        <div className="w-2/3">
          <table className="table-auto w-full overflow-y-auto">
            <thead>
              <tr>
                <th className="px-4 py-2 text-start">#</th>
                <th className="px-4 py-2 text-start">نام</th>
                <th className="px-4 py-2 text-start">مدیر تیم</th>
                <th className="px-4 py-2 text-start">نمبر تماست </th>
                <th className="px-4 py-2 text-start">آدرس</th>
                <th className="px-4 py-2 text-start">عملیه ها</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border`}
                >
                  <td className="px-4 py-2">{row.id}</td>
                  <td className="px-4 py-2">{row.name}</td>
                  <td className="px-4 py-2">{row.manager_name}</td>
                  <td className="px-4 py-2">{row.manager_phone}</td>
                  <td className="px-4 py-2">{row.manager_address}</td>
                  <td className="px-4 py-2">{row.total_machine}</td>
                  <td className="px-4 py-2 flex gap-4">
                    <button
                      onClick={() => {
                        setTeam({ ...row });
                        setId(row.id);
                      }}
                    >
                      <FaEdit className="text-green-500" />
                    </button>
                    <button
                      onClick={() =>
                        dispatch(
                          setDelete({
                            item: row,
                            url: `/api/settings/team/${row.id}/`,
                          })
                        )
                      }
                    >
                      <MdDelete className="text-red-500" size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {deleteItem.id && <Delete />}
        </div>

        <form
          action="#"
          method="post"
          onSubmit={handleSubmit}
          className=" w-1/3  rounded-t-md "
        >
          <div className=" bg-gray-100 border px-4 py-2 rounded-t-md">
            فورم ثبت تیم ها
          </div>

          <div className="p-8 border-x ">
            <div className="flex justify-between">
              <label htmlFor="name">
                {" "}
                نام<span className="text-red-500">*</span>
              </label>
              <input
                name="name"
                required
                onChange={(e) => updateTeam("name", e.target.value)}
                value={team.name}
                className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3"
              />
            </div>
            <div className="flex justify-between">
              <label htmlFor="name">
                {" "}
                نام مدیر تیم<span className="text-red-500">*</span>
              </label>
              <input
                name="manager_name"
                required
                onChange={(e) => updateTeam("manager_name", e.target.value)}
                value={team.manager_name}
                className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3"
              />
            </div>
            <div className="flex justify-between">
              <label htmlFor="name">
                {" "}
                نمبر تماس مدیر<span className="text-red-500">*</span>
              </label>
              <input
                name="name"
                required
                onChange={(e) => updateTeam("manager_phone", e.target.value)}
                value={team.manager_phone}
                className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3"
              />
            </div>
            <div className="flex justify-between">
              <label htmlFor="name">
                {" "}
                آدرس<span className="text-red-500">*</span>
              </label>
              <input
                name="manager_address"
                required
                onChange={(e) => updateTeam("manager_address", e.target.value)}
                value={team.manager_address}
                className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3"
              />
            </div>
            <div className="flex justify-between">
              <label htmlFor="name">
                {" "}
                تعداد ماشین<span className="text-red-500">*</span>
              </label>
              <input
                name="total_machine"
                type="number"
                required
                onChange={(e) => updateTeam("total_machine", e.target.value)}
                value={team.total_machine}
                className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3"
              />
            </div>
          </div>

          <div className="flex justify-end bg-gray-100 px-4 py-2 border">
            <button
              className="flex gap-2 bg-green-500 text-white rounded-sm p-2 hover:bg-green-700"
              type="submit"
              disabled={loading ? true : false}
            >
              {loading ? (
                <AiOutlineLoading3Quarters className="mt-1 animate-spin" />
              ) : (
                <IoMdAdd className="mt-1" />
              )}
              ثبت
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TeamList;
