import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createForm: false,
  editObj: null,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    showCreateForm: (state) => {
      state.createForm = !state.createForm;
    },

    setProductObj: (state, { payload }) => {
      state.editObj = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { showCreateForm, setProductObj } = productSlice.actions;
export default productSlice.reducer;
