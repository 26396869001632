import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../../../lib/axios";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { useEffect } from "react";
import { setPaymentObj } from "../../../redux/features/saleSlice";
import { toEn } from "../../../lib/toEn";
import { FaCheck } from "react-icons/fa";

const Edit = () => {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.sale.paymentEditObj);
  const [loading, setLoading] = useState(false);
  const [sales, setSales] = useState([]);
  const [formData, setFormData] = useState(payment);
  const [maxValue, setMaxValue] = useState(0);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    getSales();
    getAccounts();
  }, []);

  const getSales = () => {
    axios
      .get("/api/sales/select2")
      .then((data) => {
        setSales(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAccounts = () => {
    axios
      .get("/api/accounts/select2")
      .then((data) => {
        setAccounts(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setLoading(true);
    axios
      .put(`api/sale/payment/${payment.id}/update`, { ...formData })
      .then((data) => {
        setLoading(false);
        toast.success("عملیات موفقانه انجام شد.");
        close();
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const close = () => {
    dispatch(setPaymentObj(null));
  };

  const handleItemChange = (index, e) => {
    const value = toEn(e);
    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    if (regex.test(value)) {
      setFormData((prevValue) => ({
        ...prevValue,
        [index]: value,
      }));
    }
  };

  return (
    <>
      <div className="fixed inset-0 opacity-35 bg-black z-10"></div>
      <div className="fixed inset-0 flex justify-center items-center z-10">
        <div className="relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
            <div className="flex justify-between p-2 border-b text-gray-500 font-semibold ">
              <p>ثبت دریافتی فروشات</p>

              <button
                type="button"
                className="hover:text-gray-700 hover:font-bold"
                onClick={() => close()}
              >
                <IoClose size={18} />
              </button>
            </div>

            <form
              action=""
              onSubmit={handleSubmit}
              className="p-4 text-gray-500"
              id="createSaleForm"
            >
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  تاریخ <span className="text-red-500">*</span>
                </label>
                <input
                  name="date"
                  type="date"
                  required
                  onChange={(e) => {
                    setFormData((prevValue) => ({
                      ...prevValue,
                      date: e.target.value,
                    }));
                  }}
                  value={formData.date}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  مشتری<span className="text-red-500">*</span>
                </label>
                <Select
                  className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                  placeholder="انتخاب"
                  required
                  onChange={(e) => {
                    const balance = sales.find(
                      (row, index) => row.value === e.value
                    ).balance;
                    setFormData((prevValue) => ({
                      ...prevValue,
                      bill_id: e.value,
                      amount: balance,
                    }));
                    setMaxValue(balance);
                  }}
                  options={sales}
                  value={sales.find((row) => row.value === formData.bill_id)}
                />
              </div>

              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  حساب<span className="text-red-500">*</span>
                </label>
                <Select
                  className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                  placeholder="انتخاب"
                  required
                  onChange={(e) => {
                    const balance = accounts.find(
                      (row, index) => row.value === e.value
                    ).balance;
                    setFormData((prevValue) => ({
                      ...prevValue,
                      account_id: e.value,
                    }));
                  }}
                  options={accounts}
                  value={accounts.find(
                    (row) => row.value === formData.account_id
                  )}
                />
              </div>

              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  مقدار <span className="text-red-500">*</span>
                </label>
                <input
                  name="amount"
                  type="text"
                  required
                  max={maxValue}
                  onChange={(e) => handleItemChange("amount", e)}
                  value={formData.amount}
                  placeholder="100.00"
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  نرخ 1 دالر به افغانی <span className="text-red-500">*</span>
                </label>
                <input
                  name="currency_rate"
                  type="text"
                  required
                  max={maxValue}
                  onChange={(e) => handleItemChange("currency_rate", e)}
                  value={formData.currency_rate}
                  placeholder="71.20"
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  نوت
                </label>
                <textarea
                  name="note"
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      note: e.target.value,
                    }))
                  }
                  value={formData.note}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
            </form>

            <div className="flex  py-2 px-4 bg-gray-50 border-t justify-end">
              <button
                data-modal-hide="popup-modal"
                form="createSaleForm"
                type="subbmit"
                className="flex text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 py-1 px-4 rounded-sm"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
                ) : (
                  <FaCheck className="mt-1 mx-2" />
                )}
                ثبت گردد
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
