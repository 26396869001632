import React, { useState, useRef, useEffect } from "react";
import { FaGear } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Setting = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative z-10" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="hover:bg-gray-400 p-2 rounded-md"
      >
        <FaGear size={18} />
      </button>

      {isOpen && (
        <div className="absolute sm: right:0 lg:left-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-md">
          <Link
            to="/roles"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            وظایف
          </Link>
          <Link
            to="/users"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            کاربران
          </Link>

          <Link
            to="/settings/colors"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            رنگ ها
          </Link>
          <Link
            to="/settings/sizes"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            سایز ها
          </Link>

          <Link
            to="/settings/material-categories"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            دسته بندی مواد خام
          </Link>
          <Link
            to="/settings/categories"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            دسته بندی محصولات
          </Link>
          <Link
            to="/settings/codes"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            کود محصولات
          </Link>
          <Link
            to="/settings/teams"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            تیم های خیاطی
          </Link>
          <Link
            to="/settings/employees"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            کارمندان
          </Link>
          <Link
            to="/settings/expense-categories"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            دسته بندی مصارف
          </Link>

          <hr />

          <Link
            to="/"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            بکاب
          </Link>
          <Link
            to="/settings/setting"
            className="block px-4 py-1 text-gray-800 hover:bg-gray-200"
          >
            تنضیمات سیستم
          </Link>
        </div>
      )}
    </div>
  );
};

export default Setting;
