import React, { useEffect, useState } from "react";
import axios from "../../lib/axios";
import { toEn } from "../../lib/toEn";
import { useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";
import toast from "react-hot-toast";

import Options from "../../components/preparations/Options";
import Delete from "../../components/Delete";
import { useSelector } from "react-redux";
import Select from "react-select";
import Material from "../../components/preparations/Material";
import { TbIndentDecrease } from "react-icons/tb";

function PreparationEdit() {
  const { id } = useParams();
  const inputs = {
    id: "",
    name: "",
    description: "",
    qtys: 0.0,
    qty_price: 0.0,
    total_price: 0.0,
    item_order: "",
  };
  const [preparation, setPreparation] = useState({});
  const [existQtys, setExistQtys] = useState(0);
  const deleteItem = useSelector((state) => state.delete.item);
  const distributeCreateForm = useSelector(
    (state) => state.distribute.createForm
  );

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [codes, setCodes] = useState([]);
  const [tmpCodes, setTmpCodes] = useState([]);
  const [codeId, setCodeId] = useState("");
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    if (!distributeCreateForm) {
      getData();
    }
  }, [distributeCreateForm]);
  useEffect(() => {
    getColors();
    getCategories();
    getSizes();
  }, [id]);

  const getData = () => {
    axios
      .get(`/api/preparation/${id}/edit`)
      .then(({ data }) => {
        setPreparation(data);
        setCodeId(data.product_code_id);
        getCodes(data.product_category_id);

        console.log("wwwwwwwwwwwwwww", data);
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const getCategories = () => {
    axios.get(`/api/settings/product/category/select2`).then((data) => {
      setCategories(data);
    });
  };
  const getCodes = (id) => {
    axios.get(`/api/settings/code/select2`).then((data) => {
      setCodes(data);
      const tmp = data.filter((row) => row.product_category_id === id);
      console.log("working heree" + tmp);
      setTmpCodes(tmp);
    });
  };
  const getColors = () => {
    axios.get(`/api/settings/colors/select2`).then((data) => {
      setColors(data);
    });
  };
  const getSizes = () => {
    axios.get(`/api/settings/sizes/select2`).then((data) => {
      setSizes(data);
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .put(`/api/preparation/${id}/update`, { ...preparation })
      .then(({ data }) => {
        setLoading(false);
        setPreparation(data);
        console.log("datadddd", data);
        toast.success("عملیات موفقانه انجام شد.");
      })
      .catch((error) => {
        setLoading(false);
        const { response } = error;
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const handlePreparationChange = (e, index) => {
    const value = toEn(e);

    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    if (regex.test(value)) {
      setPreparation((prev) => ({
        ...prev,
        [index]: value,
      }));
    }
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4 ">
        <h3>نمبر تهیه {id}</h3>
        <div className="flex gap-6">
          <Options product={preparation} edit={true} />
          <button
            type="submit"
            form="submitForm"
            className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
            disabled={loading ? true : false}
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
            ) : (
              <FaCheck className="mt-1 mx-2" />
            )}
            ذخیره
          </button>
        </div>
      </div>
      <div className=" p-4">
        <div className="text-gray-500">
          <table>
            <tr>
              <th className="bg-gray-50 border w-44">قیمت اولیه فی واحد</th>
              <td className="bg-gray-50 border w-60 p-2">
                <input
                  name="material_qty_price"
                  type="number"
                  step={0.0}
                  min={0.0}
                  form="submitForm"
                  disabled
                  value={preparation.material_qty_price}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
              <td className="bg-gray-50 border p-2">دالر</td>
            </tr>
            <tr>
              <th className=" border w-44">
                متفرقه فی واحد <span className="text-red-500">*</span>
              </th>
              <td className=" border w-60 p-2">
                <input
                  name="misalleneouse_qty_price"
                  type="text"
                  required
                  form="submitForm"
                  value={preparation.misalleneouse_qty_price}
                  onChange={(e) =>
                    handlePreparationChange(e, "misalleneouse_qty_price")
                  }
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
              <td className=" border p-2">دالر</td>
            </tr>
            <tr>
              <th className="bg-gray-50 border w-44">
                فیصدی خیاط فی واحد <span className="text-red-500">*</span>
              </th>
              <td className="bg-gray-50 border w-60 p-2">
                <input
                  name="tailor_qty_percent"
                  type="text"
                  required
                  form="submitForm"
                  value={preparation.tailor_qty_percent}
                  onChange={(e) =>
                    handlePreparationChange(e, "tailor_qty_percent")
                  }
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
              <td className="bg-gray-50 border p-2">دالر</td>
            </tr>
            <tr>
              <th className=" border w-44">مجموع فی واحد</th>
              <td className=" border w-60 p-2">
                <input
                  name="total_qty_price"
                  type="number"
                  step={0.0}
                  min={0.0}
                  form="submitForm"
                  disabled
                  value={preparation.total_qty_price}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
              <td className=" border p-2">دالر</td>
            </tr>
          </table>
        </div>
        <form
          id="submitForm"
          method="post"
          onSubmit={submitForm}
          className=" mt-4 mb-12 text-gray-500"
        >
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="px-4 py-2 text-start border">
                  {" "}
                  نوع محصول <span className="text-red-500">*</span>
                </th>
                <th className="px-4 py-2 text-start border">
                  کد <span className="text-red-500">*</span>
                </th>
                <th className="px-4 py-2 text-start border">
                  رنگ <span className="text-red-500">*</span>
                </th>
                <th className="px-4 py-2 text-start border">
                  سایز <span className="text-red-500">*</span>
                </th>

                <th className="px-4 py-2 text-start border">
                  تعداد <span className="text-red-500">*</span>
                </th>
                <th className="px-4 py-2 text-start border">
                  موجودی <span className="text-red-500">*</span>
                </th>
                <th className="px-4 py-2 text-start border">
                  تاریخ <span className="text-red-500">*</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-50">
                <td className=" px-4 py-2 border align-top ">
                  {categories && (
                    <Select
                      className="ring-1 ring-inset ring-gray-300 w-32 "
                      placeholder="انتخاب"
                      required
                      onChange={(e) => {
                        setPreparation((prevValue) => ({
                          ...prevValue,
                          product_category_id: e.value,
                        }));
                        setTmpCodes(
                          codes.filter(
                            (row) => row.product_category_id === e.value
                          )
                        );
                        setCodeId(null);
                      }}
                      options={categories}
                      value={categories.find(
                        (row) => row.value === preparation.product_category_id
                      )}
                    />
                  )}
                </td>
                <td className=" px-4 py-2 border align-top ">
                  {codes && (
                    <Select
                      className="ring-1 ring-inset ring-gray-300 w-32 "
                      placeholder="انتخاب"
                      required
                      onChange={(e) => {
                        setPreparation((prevValue) => ({
                          ...prevValue,
                          product_code_id: e.value,
                        }));
                        setCodeId(e.value);
                      }}
                      options={tmpCodes}
                      value={
                        codeId
                          ? tmpCodes.find((row) => row.value === codeId)
                          : null
                      }
                    />
                  )}
                </td>

                <td className=" px-4 py-2 border align-top ">
                  {colors && (
                    <Select
                      className="ring-1 ring-inset ring-gray-300 w-32 "
                      placeholder="انتخاب"
                      required
                      onChange={(e) =>
                        setPreparation((prevValue) => ({
                          ...prevValue,
                          color_id: e.value,
                        }))
                      }
                      options={colors}
                      value={colors.find(
                        (row) => row.value === preparation.color_id
                      )}
                    />
                  )}
                </td>
                <td className=" px-4 py-2 border align-top ">
                  {sizes && (
                    <Select
                      className="ring-1 ring-inset ring-gray-300 w-32 "
                      placeholder="انتخاب"
                      required
                      onChange={(e) =>
                        setPreparation((prevValue) => ({
                          ...prevValue,
                          size_id: e.value,
                        }))
                      }
                      options={sizes}
                      value={sizes.find(
                        (row) => row.value === preparation.size_id
                      )}
                    />
                  )}
                </td>

                <td className=" px-4 py-2 border align-top ">
                  <input
                    name="code"
                    type="text"
                    required
                    form="submitForm"
                    value={preparation.qtys}
                    onChange={(e) => handlePreparationChange(e, "qtys")}
                    className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
                <td className=" px-4 py-2 border align-top ">
                  {preparation.exist_qtys}
                </td>
                <td className=" px-4 py-2 border align-top ">
                  <input
                    name="code"
                    type="date"
                    required
                    form="submitForm"
                    value={preparation.date}
                    onChange={(e) =>
                      setPreparation((prev) => ({
                        ...prev,
                        date: e.target.value,
                      }))
                    }
                    className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>

        <h3 className="flex gap-1">
          <TbIndentDecrease size={24} /> برداشت از گدام
        </h3>
        <hr />
        <Material
          id={id}
          setPreparation={setPreparation}
          preparation={preparation}
        />
      </div>

      {deleteItem.id && <Delete />}
    </div>
  );
}

export default PreparationEdit;
