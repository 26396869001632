import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../../lib/axios";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { useEffect } from "react";
import { showCreateForm } from "../../redux/features/preparationSlice";
import { toEn } from "../../lib/toEn";
import { FaCheck } from "react-icons/fa";

const CreatePreparation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const inputs = {
    color_id: "",
    qtys: "",
    date: "",
    product_category_id: "",
    product_code_id: "",
    size_id: "",
  };
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(inputs);
  const [categories, setCategories] = useState([]);
  const [codes, setCodes] = useState([]);
  const [tmpCodes, setTmpCodes] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    getColors();
    getCategories();
    getSizes();
    getCodes();
  }, []);

  const getCategories = () => {
    axios.get(`/api/settings/product/category/select2`).then((data) => {
      setCategories(data);
    });
  };
  const getCodes = () => {
    axios.get(`/api/settings/code/select2`).then((data) => {
      setCodes(data);
    });
  };
  const getColors = () => {
    axios.get(`/api/settings/colors/select2`).then((data) => {
      setColors(data);
    });
  };
  const getSizes = () => {
    axios.get(`/api/settings/sizes/select2`).then((data) => {
      setSizes(data);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setLoading(true);
    axios
      .post(`api/preparation/create`, { ...formData })
      .then((data) => {
        setLoading(false);
        toast.success("عملیات موفقانه انجام شد.");
        dispatch(showCreateForm(null));
        navigate(`/preparations/${data.preparation.id}/edit`);
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        toast.error(error.message);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const handleItemChange = (e, index) => {
    const value = toEn(e);
    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    if (regex.test(value)) {
      setFormData((prevValue) => ({
        ...prevValue,
        [index]: value,
      }));
    }
  };
  return (
    <>
      <div className="fixed inset-0 opacity-35 bg-black"></div>
      <div className="fixed inset-0 flex justify-center items-center z-10">
        <div className="relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
            <div className="flex justify-between p-2 border-b text-gray-500 font-semibold ">
              <p> ثبت تهیه</p>

              <button
                type="button"
                className="hover:text-gray-700 hover:font-bold"
                onClick={() => dispatch(showCreateForm(null))}
              >
                <IoClose size={18} />
              </button>
            </div>

            <form
              action=""
              onSubmit={handleSubmit}
              className="p-4 text-gray-500"
              id="createPurchaseForm"
            >
              {categories.length > 0 && (
                <div className="flex gap-8 items-center mb-3">
                  <label htmlFor="name" className="w-1/3 md:w-1/4">
                    نوع محصول
                    <span className="text-red-500">*</span>
                  </label>
                  <Select
                    className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                    placeholder="انتخاب"
                    required
                    onChange={(e) => {
                      setFormData((prevValue) => ({
                        ...prevValue,
                        product_category_id: e.value,
                      }));
                      setTmpCodes(() =>
                        codes.filter(
                          (row) => row.product_category_id === e.value
                        )
                      );
                    }}
                    options={categories}
                  />
                </div>
              )}
              {codes.length > 0 && (
                <div className="flex gap-8 items-center mb-3">
                  <label htmlFor="name" className="w-1/3 md:w-1/4">
                    کد
                    <span className="text-red-500">*</span>
                  </label>
                  <Select
                    className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                    placeholder="انتخاب"
                    required
                    onChange={(e) =>
                      setFormData((prevValue) => ({
                        ...prevValue,
                        product_code_id: e.value,
                      }))
                    }
                    options={tmpCodes}
                  />
                </div>
              )}
              {colors.length > 0 && (
                <div className="flex gap-8 items-center mb-3">
                  <label htmlFor="name" className="w-1/3 md:w-1/4">
                    رنگ
                    <span className="text-red-500">*</span>
                  </label>
                  <Select
                    className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                    placeholder="انتخاب"
                    required
                    onChange={(e) =>
                      setFormData((prevValue) => ({
                        ...prevValue,
                        color_id: e.value,
                      }))
                    }
                    options={colors}
                  />
                </div>
              )}
              {sizes.length > 0 && (
                <div className="flex gap-8 items-center mb-3">
                  <label htmlFor="name" className="w-1/3 md:w-1/4">
                    سایز
                    <span className="text-red-500">*</span>
                  </label>
                  <Select
                    className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                    placeholder="انتخاب"
                    required
                    onChange={(e) =>
                      setFormData((prevValue) => ({
                        ...prevValue,
                        size_id: e.value,
                      }))
                    }
                    options={sizes}
                  />
                </div>
              )}
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  تعداد <span className="text-red-500">*</span>
                </label>

                <input
                  name="qtys"
                  type="text"
                  required
                  onChange={(e) => handleItemChange(e, "qtys")}
                  value={formData.qtys}
                  placeholder="60"
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  تاریخ <span className="text-red-500">*</span>
                </label>
                <input
                  name="date"
                  type="date"
                  required
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      date: e.target.value,
                    }))
                  }
                  value={formData.date}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
            </form>

            <div className="flex  py-2 px-4 bg-gray-50 border-t justify-end">
              <button
                data-modal-hide="popup-modal"
                form="createPurchaseForm"
                type="subbmit"
                className="flex text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 py-1 px-4 rounded-sm"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
                ) : (
                  <FaCheck className="mt-1 mx-2" />
                )}
                ثبت گردد
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePreparation;
