import React, { useEffect, useState } from "react";
import axios from "../../lib/axios";
import Select from "react-select";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { toEn } from "../../lib/toEn";

function Material({ id, setPreparation, preparation }) {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [colors, setColors] = useState([]);
  const [color, setColor] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [select, setSelect] = useState(null);
  const [withdraws, setWithdraws] = useState([]);

  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    getColors();
    getCategories();
    getWithdraws();
  }, []);

  useEffect(() => {
    if (color && category) {
      getMaterials();
    }
  }, [color, category]);

  const getCategories = () => {
    axios.get(`/api/settings/material/category/select2`).then((data) => {
      setCategories(data);
    });
  };

  const getColors = () => {
    axios.get(`/api/settings/colors/select2`).then((data) => {
      setColors(data);
    });
  };

  const getMaterials = () => {
    axios
      .post("/api/preparation/materials", {
        category,
        color,
      })
      .then((data) => {
        setMaterials(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getWithdraws = () => {
    axios
      .get(`/api/preparation/${id}/withdraws`)
      .then((data) => {
        setWithdraws(data);
        console.log("withdrawwss", data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitWithdraw = () => {
    axios
      .post(`/api/preparation/${id}/withdraw`, {
        ...select,
      })
      .then((data) => {
        const temp = data[0];
        setWithdraws((prevVal) => [...prevVal, temp]);
        updatePreparation(temp, "+");
        // setMaterials(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatePreparation = (temp, operator) => {
    setPreparation((prevVal) => {
      let add = (temp.qtys * temp.qty_price) / prevVal.qtys;
      if (operator === "-") {
        add = -add.toFixed(3);
      }
      console.log("prrrrrrr", parseFloat(prevVal.material_qty_price) + add);
      return {
        ...prevVal,
        material_qty_price: parseFloat(prevVal.material_qty_price) + add,
        total_qty_price: parseFloat(prevVal.total_qty_price) + add,
      };
    });
  };

  const deleteWithdraw = () => {
    axios
      .delete(`/api/preparation/${deleteItem.id}/withdraw/delete`)
      .then((data) => {
        updatePreparation(deleteItem, "-");
        setWithdraws((prev) =>
          prev.filter((item) => item.id !== deleteItem.id)
        );
        setDeleteItem(null);
        // setMaterials(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectChange = (e, index) => {
    const value = toEn(e);

    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    if (regex.test(value)) {
      setSelect((prev) => ({
        ...prev,
        [index]: value,
      }));
    }
  };
  return (
    <div className="mt-4 flex flex-col lg:flex-row justify-start gap-4">
      <div className="lg:w-1/2 text-gray-500">
        <div className="py-2 px-4 font-bold border rounded-t-md text-lg bg-gray-100">
          فورم برداشت از گدام مواد خام
        </div>
        <div className="py-2 px-4  border">
          {categories.length > 0 && (
            <div className="flex gap-8 items-center mb-3">
              <label htmlFor="name" className="w-1/3 md:w-1/4">
                کتگوری
                <span className="text-red-500">*</span>
              </label>
              <Select
                className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                placeholder="انتخاب"
                required
                onChange={(e) => setCategory(e.value)}
                options={categories}
              />
            </div>
          )}
          {colors.length > 0 && (
            <div className="flex gap-8 items-center mb-3">
              <label htmlFor="name" className="w-1/3 md:w-1/4">
                رنگ
                <span className="text-red-500">*</span>
              </label>
              <Select
                className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                placeholder="انتخاب"
                required
                onChange={(e) => setColor(e.value)}
                options={colors}
              />
            </div>
          )}
        </div>

        <div className="mt-8">
          {select && (
            <div className="flex justify-between border p-2">
              <input
                type="text"
                className="ring-1 ring-inset ring-gray-300 py-1 px-2 text-xl font-bold"
                onChange={(e) => handleSelectChange(e, "exist_qtys")}
                value={select.exist_qtys}
              />
              <button
                onClick={submitWithdraw}
                className="flex bg-green-500 text-white px-4 py-2 rounded-sm font-bold"
              >
                برداشت <FaLongArrowAltLeft className=" mx-2" size={24} />
              </button>
            </div>
          )}
          <table className="w-full">
            <tr>
              <th className="border">#</th>

              <th className="border">موجودی</th>
              <th className="border">قیمت فی واحد</th>
            </tr>
            {materials.map((row, index) => (
              <tr
                key={index}
                className="hover:bg-amber-200 cursor-pointer"
                onClick={() => setSelect(row)}
              >
                <td className="py-2 border">{row.id}</td>
                <td className="py-2 border">{row.exist_qtys}</td>
                <td className="py-2 border">{row.qty_price}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
      <div className="lg:w-1/2 text-gray-500">
        <div className="py-2 px-4 font-bold border rounded-t-md text-lg bg-gray-100">
          برداشتی ها
        </div>
        <div className="border">
          {deleteItem && (
            <div className="bg-red-500 text-white rounded-sm py-2 px-4 flex justify-between m-2">
              از حذف این ریکات مطمعن هستید؟{" "}
              <div className="flex gap-3">
                <button
                  onClick={() => deleteWithdraw()}
                  className="bg-white rounded-sm px-4 text-red-500 hover:bg-gray-100"
                >
                  بلی
                </button>{" "}
                <button
                  onClick={() => setDeleteItem(null)}
                  className="bg-white rounded-sm px-4 text-gray-500 hover:bg-gray-100"
                >
                  نخیر
                </button>
              </div>
            </div>
          )}
          <table className="w-full">
            <tr>
              <th className="border">#</th>

              <th className="border">نوع محصول</th>
              <th className="border">رنگ</th>
              <th className="border">تعداد</th>
              <th className="border">قیمت مجموعی</th>
              <th></th>
            </tr>
            {withdraws.map((row, index) => (
              <tr key={index} className="">
                <td className="py-2 border">{row.id}</td>
                <td className="py-2 border">{row.mname}</td>
                <td className="py-2 border">{row.name}</td>
                <td className="py-2 border">{row.qtys}</td>
                <td className="py-2 border">
                  {(row.qty_price * row.qtys).toFixed(2)}
                </td>
                <td className="py-2 border">
                  <button onClick={() => setDeleteItem(row)}>
                    <MdDelete size={22} className="text-red-500" />
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
}

export default Material;
