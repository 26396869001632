import React, { useEffect, useState } from "react";
import { IoIosAddCircle, IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toEn } from "../../lib/toEn";
import axios from "../../lib/axios";
import toast from "react-hot-toast";
import { setDelete } from "../../redux/features/deleteSlice";
import { MdDelete } from "react-icons/md";
import Delete from "../../components/Delete";
import TransactionList from "../../components/accounts/transactions/TransactionList";

function AccountList() {
  const dispatch = useDispatch();
  const input = {
    name: "",
    type: "",
    base_liquidity: "",
  };
  const deleteItem = useSelector((state) => state.delete.item);
  const createForm = useSelector((state) => state.transaction.createForm);
  const editObj = useSelector((state) => state.transaction.editObj);

  const [formData, setFormData] = useState(input);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    if (!deleteItem.id && !formData.name && !createForm && !editObj) {
      getAccounts();
    }
  }, [deleteItem, formData, createForm, editObj]);

  const getAccounts = () => {
    axios
      .get("/api/accounts")
      .then((data) => {
        setAccounts(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("teaam", formData);
    axios
      .post("/api/account/create", { id, ...formData })
      .then((data) => {
        setLoading(false);
        toast.success("موفقانه ثبت گردید.");
        setFormData(input);
        setId(null);
      })
      .catch((error) => {
        setLoading(false);
        const message = error?.response?.data?.message;
        toast.error(message ? message : error.message);
        console.log(error);
      });
  };

  const handleItemChange = (index, e) => {
    const value = toEn(e);
    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    console.log(value);
    if (regex.test(value)) {
      setFormData((prev) => ({ ...prev, [index]: value }));
    }
  };
  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>بخش حسابات و تبادلات </h3>
      </div>
      <div className="lg:flex lg:flex-row md:flex-col p-4  gap-4">
        <div className="lg:w-3/5  bg-gray-100 rounded-md p-4">
          <div className="flex flex-row flex-wrap gap-4 justify-evenly">
            {accounts.map((row, index) => (
              <div key={index} className="w-100 w-50-sm w-33-md w-25-lg">
                <div className="bg-white text-gray-800 p-8 shadow-lg relative max-h-44 w-60 ">
                  <span className=" text-white rounded-full p-1 text-xs absolute  bg-blue-500 top-1 right-1 px-2 font-bold">
                    {++index}
                  </span>
                  <div className="text-center my-3">
                    <span className=" text-white rounded-full p-1 text-xs absolute  bg-amber-500 top-1 left-1">
                      {row.type}
                    </span>
                    <h1 className="text-2xl" dir="ltr">
                      {row.amount}
                    </h1>
                    <hr />
                    <h3 className=" text-2xl">{row.name}</h3>
                  </div>

                  <div className=" text-sm absolute flex justify-between  bg-amber-500 bottom-0 right-0 w-full text-white font-semibold p-1">
                    <span>شروع: {row.base_liquidity} </span>

                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          setFormData({ ...row });
                          setId(row.id);
                        }}
                        className="bg-green-500 text-xs rounded-sm p-1 hover:bg-green-700"
                      >
                        ویرایش
                      </button>
                      <button
                        onClick={() =>
                          dispatch(
                            setDelete({
                              item: row,
                              url: `/api/account/${row.id}/`,
                            })
                          )
                        }
                        className="bg-red-500 text-xs rounded-sm p-1 hover:bg-red-700"
                      >
                        حذف
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <form
          action="#"
          method="post"
          onSubmit={handleSubmit}
          className="lg:w-2/5  rounded-t-md mt-4 lg:mt-0"
        >
          <div className=" bg-gray-100 border px-4 py-2 rounded-t-md">
            فورم ثبت حساب
          </div>

          <div className="p-8 border-x ">
            <div className="flex justify-between">
              <label htmlFor="name">
                {" "}
                نام<span className="text-red-500">*</span>
              </label>
              <input
                name="name"
                required
                placeholder="صندوق افغانی"
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, name: e.target.value }))
                }
                value={formData.name}
                className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3"
              />
            </div>

            <div className="flex justify-between">
              <label htmlFor="name">
                شروع نقدینه گی
                <span className="text-red-500">*</span>
              </label>
              <input
                name="base_liquidity"
                required
                placeholder="100.00"
                onChange={(e) => handleItemChange("base_liquidity", e)}
                value={formData.base_liquidity}
                className="ring-1 ring-inset ring-gray-300  mb-3 p-2 rounded-md w-2/3"
              />
            </div>
            <div className="flex justify-between">
              <label htmlFor="name">
                نوع حساب
                <span className="text-red-500">*</span>
              </label>
              <div className="flex gap-8 justify-start  w-2/3">
                <label className="flex gap-2 border rounded-md py-1 px-4">
                  <input
                    type="radio"
                    name="options"
                    value="دالر"
                    checked={formData.type === "دالر"}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        type: e.target.value,
                      }))
                    }
                  />
                  دالر
                </label>
                <label className="flex gap-2 border rounded-md py-1 px-4">
                  <input
                    type="radio"
                    name="options"
                    value="افغانی"
                    checked={formData.type === "افغانی"}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        type: e.target.value,
                      }))
                    }
                  />
                  افغانی
                </label>
              </div>
            </div>
          </div>

          <div className="flex justify-end bg-gray-100 px-4 py-2 border">
            <button
              className="flex gap-2 bg-green-500 text-white rounded-sm p-2 hover:bg-green-700"
              type="submit"
              disabled={loading ? true : false}
            >
              {loading ? (
                <AiOutlineLoading3Quarters className="mt-1 animate-spin" />
              ) : (
                <IoMdAdd className="mt-1" />
              )}
              ثبت
            </button>
          </div>
        </form>
        {deleteItem.id && <Delete />}
      </div>

      <TransactionList />
    </div>
  );
}

export default AccountList;
